.centered-card-wrapper {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 100px;

    @media (max-width: 576px) {
        padding-top: 0px;
        height: 100%;
        width: 100%;
        justify-content: stretch;
        align-content: stretch;

        .centered-card {
            border:none;
            box-shadow: none;
        }
    }

    .centered-card {
        .mat-card-title, .mat-card-subtitle {
            text-align: center;

            h2,h3 {

                margin-bottom: 6px;
                margin-top: 2px;
            }
        }

        position:relative;
        overflow: hidden;
        width: 576px;
        max-width: 100%;
        padding: 16px;
        
        .mat-list-item {
            height: 72px;

            .mat-list-item-content {
                flex-direction: column;
                align-items: flex-start;
            }
        }

        .form-row {
            display:flex;
            flex-direction:row;
            justify-content: center;
            align-content: center;
            align-items: flex-start;
            button {
                display: block;
                margin-top: 8px;
                margin-left: 12px;
            }
        }
    }
}
