$primary: #6264a7;

* {
    box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0px;

  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  position: relative;
  overflow: hidden;
  background-color: #d3d3d3;
  
}

body {
    padding: 0.025rem;
    border-radius: 0.225rem;
  
    a,
    a:visited,
    a:hover {
      text-decoration: none;
    }
  
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0px;
    }
  
    .fw,
    [appearance="fill"] {
      width: 100%;
    }

    .fw,
    [appearance="outline"] {
      width: 100%;
    }
  
    .fh {
      height: 100%;
    }
  
    .fgap-0d5 {
      gap: 0.5rem;
    }
  
    .fgap-0d4{
      gap: 0.4rem;
    }
  
    .relative {
      position: relative;
    }

    .txt-red {
      color: #bd432c;
    }
  
    .frow,
    .fcol,
    .frow-top,
    .frow-middle,
    .frow-rev,
    .fcol-rev {
      display: flex;
  
      .fauto {
        flex: auto;
      }
  
      .fx {
        flex: 1;
      }
  
      .fx-2 {
        flex: 2;
      }
  
      .fx-3 {
        flex: 3;
      }
  
      .fx-4 {
        flex: 4;
      }
  
      .fx-6 {
        flex: 6;
      }
  
      .fsb {
        justify-content: space-between;
      }
  
      .fsa {
        //self aligned
        justify-content: space-around;
      }
  
      .fsb {
        justify-content: space-between;
      }
  
      &.fjc-end {
        justify-content: flex-end;
      }
  
      .fsj-left {
        justify-self: flex-start;
      }
  
      .fjs-center {
        justify-self: center;
      }
  
      .fsj-right {
        justify-self: flex-end;
      }
  
      .fas-top {
        align-self: flex-start;
      }
  
      .fas-center {
        align-self: center;
      }
  
      .fas-bottom {
        align-self: flex-end;
      }
  
      .fg {
        flex-grow: 1;
      }
  
      .ftop {
        align-items: flex-start;
      }
  
      .fend {
        align-items: flex-end;
      }
  
      .fmiddle {
        align-items: center;
      }
  
      .fbaseline {
        align-items: baseline;
      }
  
      .fcenter {
        justify-content: center;
      }
  
      &.fmiddle {
        align-items: center;
      }
  
      &.fcenter {
        justify-content: center;
      }
    }
  
    .fwrap {
      flex-wrap: wrap;
    }
  
    .overflow {
      overflow: hidden;
      overflow-y: auto;
    }
  
    .hrz-hdn {
      overflow-x: hidden;
    }
  
    .tc {
      text-align: center;
    }

    .te {
      text-align: end;
    }

    .padding-1em{
      padding: 1em;
    }
  
    .upper {
      text-transform: uppercase;
    }
  
    .frow,
    .frow-top,
    .frow-middle {
      flex-direction: row;
    }
  
    .frow-middle {
      align-items: center;
    }
  
    .frow-top {
      align-items: flex-start;
    }
  
    .frow-rev {
      flex-direction: row-reverse;
    }
  
    .fcol-rev {
      flex-direction: column-reverse;
    }
  
    .fcol {
      flex-direction: column;
    }

    .fauto {
      flex: auto;
    }

    .fx {
      flex: 1;
    }

    .fx-2 {
      flex: 2;
    }

    .fx-3 {
      flex: 3;
    }

    .fx-4 {
      flex: 4;
    }

    .fx-6 {
      flex: 6;
    }

    .fsb {
      justify-content: space-between;
    }

    .fsa {
      //self aligned
      justify-content: space-around;
    }

    .fsb {
      justify-content: space-between;
    }

    .fjc-end {
      justify-content: flex-end;
    }

    .fsj-left {
      justify-self: flex-start;
    }

    .fjs-center {
      justify-self: center;
    }

    .fsj-right {
      justify-self: flex-end;
    }

    .fas-top {
      align-self: flex-start;
    }

    .fas-center {
      align-self: center;
    }

    .fas-bottom {
      align-self: flex-end;
    }

    .fg {
      flex-grow: 1;
    }

    .ftop {
      align-items: flex-start;
    }

    .fend {
      align-items: flex-end;
    }

    .fmiddle {
      align-items: center;
    }

    .fbaseline {
      align-items: baseline;
    }

    .fcenter {
      justify-content: center;
    }

    .fmiddle {
      align-items: center;
    }

    .fcenter {
      justify-content: center;
    }

    .light-bg {
      background-color: #d3d3d3;
    }
  
    .cpointer {
      cursor: pointer;
    }
  
    .hvr-80 {
      opacity: 0.8;
    }
  
    .hvr-80:hover {
      opacity: 1;
    }
  
    .disable-op {
      opacity: 0.5;
    }
  
    .bold {
      font-weight: bold;
    }
  
    .lighter {
      font-weight: lighter;
    }
  
    .loading-wrapper {
      display: flex;
      align-items: center;
  
      & > span {
        padding-left: 0.8rem;
      }
    }
  
    .ql-toolbar.ql-snow {
      padding: 0.86rem 0.58rem;
      border: none;
    }
  
    .ql-container.ql-snow {
      border: none;
      font-size: 1rem;
    }
  
    quill-editor.with-btn {
      .ql-toolbar.ql-snow {
        padding-right: 6rem;
      }
    }
  
    .field-err {
      font-size: 0.75rem;
      padding: 0.1rem 1rem 0rem;
      margin-bottom: -1.4rem;
    }
  
    .err-cntnr {
      padding: 0.5rem;
      font-size: smaller;
      border-radius: 0.5rem;
      margin-bottom: 1rem;
  
      & > ul {
        margin: 0px;
        list-style-position: inside;
        padding-left: 1rem;
      }
    }
  
    .lnk-btn {
      border: none;
      background: none;
      font-size: 1rem;
      padding: 0 0.3rem;
      cursor: pointer;
      line-height: 1em;
  
      &:hover {
        & > * {
          background-color: transparent;
        }
  
        text-decoration: underline;
      }
    }
  
    .btn {
        padding: 0.625rem 1.25rem;
        font-size: 0.85rem;
        font-weight: normal;
        line-height: 1rem;
        // background-color: $primary;

        &:disabled {
            cursor: not-allowed;

        }
    }
  
    .flex-btn {
      .mat-button-wrapper {
        display: flex;
        align-content: center;
        justify-content: center;
  
        & > * {
          display: inline-block;
        }
  
        & > :not(:last-child) {
          margin-right: 0.375rem;
        }
      }
    }
  
    .flex-btn-grp {
      .btn {
        @extend .flex-btn;
      }
    }

    td{
      padding: 0em 1em;
    }
    th{
      padding: 1em;
    }
    .td-center{
      vertical-align: top;
      padding-top: 1em;
    }

  .profile-info{
    border: 1px solid lightgray;
    border-radius: 5px;
  
    &__header{
      border-bottom: 1px solid lightgray;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__details{
      padding: 1em;

      form{
        padding: 1em;
        div{
          @media (max-width: 500px) {
            flex-direction: column;
          }
        }
      }
    }
  
    &__details-flex{
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__icon{
        min-width: 250px;
        flex: 1;
        @media (max-width: 900px) {
          min-width: 150px;
        }
        @media (max-width: 400px) {
          min-width: 100px;
        }
        align-self: stretch;
        .mat-icon{
          font-size: 100px;
          @media (max-width: 400px) {
            font-size: 70px;
          }
          @media (max-width: 350px) {
            font-size: 50px;
          }
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  
    &__actions{
      align-items: end;
      display: flex;
      flex-direction: row-reverse;
      padding: 1em;
    }
  }
  table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 0.5em;
    overflow: hidden;
    .mobile-label {
        display: none;
    }
    @media(max-width: 800px) {

      .mobile-label {
          min-width: 100px;
          display: inline-block;
          font-weight: bold;
      }
      
      .mat-header-row {
          display: none;
      }
      
      .mat-row { 
          display: flex;
          flex-direction: column;
          align-items: start;
          padding: 8px 24px;
          height: fit-content;
      }
      .mat-cell:first-of-type{
          padding: 0;
      }
    }
  }

  .work-loader{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000052;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;
  }

  .visibleLoader{
    // visibility: visible;
    display: flex;
  }
  .hiddenLoader{
    // visibility: hidden;
    display: none;
  }
  .visibleWorkArea{
    display: block;
  }
  .hiddenWorkArea{
    display: none;
  }

  .cancel{
    text-align: end;
    color: red;
    font-weight: 500;
  }
}
.m-visible {
    display: none;
}
  
div.mat-list {
    padding-top: 0 !important;
}
  
/* Keep till here*/
  
mat-card {
    border-radius: 0;
}

td.mat-cell {
  border: none !important;
}

.mat-paginator{
  @media (max-width: 500px) {
    font-size: xx-small;
  }
}
.mat-paginator-container{
  @media (max-width: 400px) {
    padding: 0px 4px !important;
  }
}
.mat-paginator-range-label{
  @media (max-width: 400px) {
    margin: 0px 10px !important;
  }
}

::ng-deep ngx-intl-tel-input input {
	height: 44px;
	margin-bottom: 20px;
	padding: 10px;
	background-color: rgba(0, 0, 0, 0.04);
	border-color: #c7cace;
	font-size: 18px;

	width: 100%;
	border: none;
	border-bottom: 1px solid black;
    border-radius: 4px 4px 0 0;
    padding: 0 0.75em 0 0.75em;
}

::ng-deep ngx-intl-tel-input{
	width: 100%;
}

::ng-deep ngx-intl-tel-input .iti{
	width: 100%;
	margin-bottom: 1.34375em;
	box-sizing: border-box;
}

::ng-deep ngx-intl-tel-input input:focus {
	outline: none !important;
	border: none;
	box-shadow: 0 0 0 0 #000;
	border-bottom: 1px solid #3f51b5;
}

.mat-row:nth-child(even){
  background-color: #f1f1f1;
}
      
// .mat-row:nth-child(odd){
//   background-color: black;
// }
  
.user-display {
    display: flex;
    flex-direction: row;
    align-items: center;
}
  
span.mat-option-text {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
}

.mat-paginator-sticky {
  bottom: 0px;
  position: sticky;
  z-index: 10;
}

.example-table-container {
  position: relative;
  height: 82%;
  padding: 20px;
  padding-top: 5px;
  @media screen and (max-width: 500px) {
    height: 70%;
  }
  overflow: auto;
}
  
mat-tooltip-component .mat-tooltip {
    font-size: 0.875rem;
}

mat-tab-body .mat-tab-body-content{
  overflow: hidden;
}
  
  /* Responsive Styles */
@media screen and (max-width: 767px) {
    .card-container > *:not(.circle-link), .terminal {
        width: 100%;
    }

    .card:not(.highlight-card) {
        height: 16px;
        margin: 8px 0;
    }

    .card.highlight-card span {
        margin-left: 72px;
    }
}
  
.ptr-not-allowed {
    cursor: not-allowed;
}
  
.ellipsis, .ellipsis > span {
    // display: block;
    // max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ellipsis-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden !important;

}
  
.pad-left-5 {
    padding-left: 0.5rem;
}
.pad-bottom-1 {
  padding-bottom: 1rem;
}
.mar-bottom-1 {
  margin-bottom: 1em;
}
.mar-bottom-2 {
  margin-bottom: 2em;
}
  
.pad-right-5 {
    padding-right: 0.5rem;
}
  
.pad-left-1 {
    padding-left: 1rem;
}

.pad-right-1 {
    padding-right: 1rem;
}

.marg-left-5 {
    margin-left: 0.5rem;
}

.half-width {
  width: 50%;
  @media (max-width: 600px) {
    width: 100%;
  }
}
  
.marg-right-5 {
    margin-right: 0.5rem;
}

.marg-top-5 {
    margin-top: 0.5rem;
}
  
.txt-italics {
    font-style: italic;
    padding-right: 0.1rem;
}
  
.italic {
    font-style: italic;
}
  
.pad-all-08 {
    padding: 0.8rem;
}
  
.c-disabled {
    opacity: 0.1;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    z-index: 7;
}

.link{
  color: #3f51b5;
  font-weight: 500;
}
  
.err-icon {
    font-size: 1.125rem;
}
  
.touch-auto-tooltip {
    touch-action: auto !important;
}
  
.no-pntr-events {
    pointer-events: none;
}
  
@media all and (display-mode: standalone) {
    .dummyClasstoCheckStandAlone {
        height: 100%;
    }
}

.mat-header-cell {
  background: #3f51b5;
  color: white;
    
}
.mat-sort-header-arrow {
  color: #fff;
} 

.table-loader{
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 0.5em;

  .loader-tooltip{
    margin-left: 10px;
  }
}

.icon-bg{
  background-color: white;
  border-radius: 10px;
  position: relative;
  left: 90%;
  bottom: 20px;
  width: fit-content;
}