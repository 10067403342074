// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '~@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

@tailwind base;
@tailwind components;
@tailwind utilities;
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ensqual-primary: mat.define-palette(mat.$indigo-palette, 700);
$ensqual-accent: mat.define-palette(mat.$pink-palette, A400);

// The warn palette is optional (defaults to red).
$ensqual-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ensqual-theme: mat.define-light-theme((
  color: (
    primary: $ensqual-primary,
    accent: $ensqual-accent,
    warn: $ensqual-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ensqual-theme);

/* You can add global styles to this file, and also import other style files */

@import "assets/styles/generic";
@import "assets/styles/element";
@import "assets/styles/centered-box";
/* Importing Bootstrap SCSS file. */
// @import "~bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "~ngx-bootstrap/datepicker/bs-datepicker";

h1,
h2,h3,h4,h5,h6,p,span {
  font-family: "SUSE", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}


body {
background: #fff;
}

.button-red {
  background-color: red;
  color: #fff;

}

.button-green {
  background-color: green;
  color: #fff;
}

.verified-icon-x {
  color: red;
}


.verified-icon-tick {
  color: green;
}